import React, { useEffect, useRef, useState } from "react";
import "./layout.css";
import Navbar from "../../Components/HomePage/Components/Navbar";
import Hero from "./home/Hero";

import OneGuarantee from "./home/OneGuarantee";
import Roadmap from "./home/Roadmap";
import Cookies from "js-cookie";
import Experiences from "../../Components/Experiences";
import Faq from "../../InternshipTrack/pages/home/Faq";
import Footer from "../../InternshipTrack/pages/home/Footer";
import WhyTutedude from "./home/WhyTutedude";
import Backoffer from "../../Components/Refund";
import DoubtSolve from "./home/DoubtSolve";
import "../global.css";
import { useNavigate } from "react-router-dom";
import useCart from "../../contexts/Cart";
import Login from "../../Components/Login";
import ForgotPass from "../../Components/ForgotPass";
import { Helmet } from "react-helmet";
import data from "./Contents/ProPack.js";
import SignUp from "../../Components/SignUp.js";
import TrackHiringCompanies from "../../Components/TrackPages/TrackHiringCompanies.js";
import ProPackCards from "../../Components/HomePage/Components/ProPackCards.jsx";
import OtherCourses from "../../Components/OtherCourses.jsx";
import VideoCarousel from "../../Components/VideoCarousel.jsx";
function Layout({
  slug = "datascientist",
  setShowLogin,
  showLogin,
  showSign,
  setShowSign,
}) {
  const customSlideToExploreRef = useRef(null);
  const [showFloatSelected, setShowFloatSelected] = useState(false);

  const [redirect, setRedirect] = useState(null);
  const [showForgot, setShowForgot] = useState(false);

  const { setSelectedCourse, setSelectedCategory } = useCart();
  const name = Cookies.get("user_name");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      if (customSlideToExploreRef.current) {
        const customSlideToExploreRect =
          customSlideToExploreRef.current.getBoundingClientRect();
        const scrollPosition = window.scrollY || window.pageYOffset;
        if (scrollPosition >= customSlideToExploreRect.top) {
          setShowFloatSelected(true);
        } else {
          setShowFloatSelected(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="pro-pac-layout">
      {showSign && (
        <SignUp
          setShowSign={setShowSign}
          redirect={redirect}
          setShowLogin={setShowLogin}
        />
      )}
      {showFloatSelected && (
        <div className="mobile-floater">
          <div
            className="mobile-floater-inner "
            onClick={() => {
              setSelectedCategory("combo");
              if (!name) {
                setSelectedCourse((prev) => ({
                  ...prev,
                  combo: [data[slug]?.curric, data[slug]?.price],
                }));
                setRedirect("/packpayment");
                setShowSign(true);
              } else {
                setSelectedCourse((prev) => ({
                  ...prev,
                  combo: [data[slug]?.curric, data[slug]?.price],
                }));
                navigate("/packpayment");
              }
            }}
          >
            Enroll Now for ₹{data[slug]?.price}
          </div>
        </div>
      )}
      {showLogin && (
        <Login
          slug={slug}
          redirect={redirect}
          setShowLogin={setShowLogin}
          setShowSign={setShowSign}
          setShowForgot={setShowForgot}
        />
      )}
      {showForgot && <ForgotPass setShowForgot={setShowForgot} />}
      <Helmet>
        <title>{data[slug].pagetitle}</title>
      </Helmet>
      <Navbar
        setShowSign={setShowSign}
        redirect={redirect}
        setShowLogin={setShowLogin}
      />
      <Hero
        courses={data[slug].curric?.length}
        head={data[slug].heroHead}
        curric={data[slug].curric}
        setShowLogin={setShowSign}
        setRedirect={setRedirect}
        price={data[slug]?.price}
      />
      <div ref={customSlideToExploreRef}>
        <Roadmap heroHead={data[slug].heroHead} courses={data[slug].curric} />
      </div>

      <Backoffer price={data[slug]?.price} months="24" />
      <OneGuarantee />
      <ProPackCards slug={slug} />
      <OtherCourses hideHeader={true} />
      <DoubtSolve />

      {/* <CareerOptions />
      <Guarantee /> */}

      {/* <CareerOptions
        hide={true}
        head={data[slug]?.heroHead}
        careers={data[slug]?.careers}
        tools={data[slug]?.tools}
      /> */}

      <TrackHiringCompanies data={{ curricHead: data[slug].heroHead }} />
      <WhyTutedude />
      <VideoCarousel slug={slug} />
      <Experiences />

      <Faq />
      <Footer />
    </div>
  );
}

export default Layout;
