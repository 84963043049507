import React from "react";
import "./styles/Internship.css";

import "react-multi-carousel/lib/styles.css";

import SliderPro from "./SliderPro";

const ProPackCards = ({ slug }) => {
  // console.log(slug);
  return (
    <div className="int-cont" id="combo">
      <div className="comboo-bg-oval oval1"></div>
      <div className="comboo-bg-oval oval2"></div>
      <div className="comboo-bg-oval oval3"></div>
      <div className="home-wu-top">
        <div className="home-hr-head headings-col text-white">
          Interested in our{" "}
          <b className="home-hr-head headings-col-col text-white">
            Combo Packs ?
          </b>
        </div>
      </div>

      <SliderPro slug={slug} />
    </div>
  );
};

export default ProPackCards;
