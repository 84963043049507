import React from "react";
import "../../styles/applybtn.css";
import useCart from "../../../contexts/Cart";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
function ApplyBtn({ classes, data, setShowLogin, setRedirect }) {
  const { setSelectedCourse } = useCart();
  const navigate = useNavigate();
  const name = Cookies.get("user_name");
  return (
    <p
      className={`int-track-apply-now-btn ${classes}`}
      onClick={() => {
        if (!name) {
          setSelectedCourse((prev) => ({
            ...prev,
            tracks: [data.curric],
          }));
          setRedirect("/trackspayment");
          setShowLogin(true);
        } else {
          setSelectedCourse((prev) => ({
            ...prev,
            tracks: [data.curric],
          }));
          navigate("/trackspayment");
        }
      }}
    >
      Enroll now for ₹ {data?.price}
    </p>
  );
}

export default ApplyBtn;
