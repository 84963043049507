// import React from "react";
// import ocs1 from "../Assests/latest/ocs1.png";
// import ocs2 from "../Assests/latest/ocs2.png";
// const OtherCourses = () => {
//   return (
//     <div className="ocs-cont">
//       <div className="headings">
//         Interested In Our <br />
//         <span className="headings-col">Other Courses Too ? </span>
//       </div>
//       <div className="ocs-inner-cont">
//         <div className="ocs-card">
//           <div className="ocs-card-inner">
//             <div className="ocs-left">
//               <div className="ocs-lt">
//                 Customized course Pack
//                 <div className="ocs-lt-det">
//                   {/* <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="20"
//                     height="20"
//                     viewBox="0 0 20 20"
//                     fill="none"
//                   >
//                     <path
//                       d="M7.09243 1.66663H12.9092C13.1029 1.66658 13.2514 1.66655 13.3813 1.67925C14.3044 1.76956 15.0601 2.32461 15.3805 3.07227H4.62109C4.94152 2.32461 5.69715 1.76956 6.62034 1.67925C6.75017 1.66655 6.8987 1.66658 7.09243 1.66663Z"
//                       fill="#800080"
//                       fillOpacity="0.5"
//                     />
//                     <path
//                       d="M5.25964 3.93591C4.10079 3.93591 3.15057 4.6357 2.83346 5.56407C2.82685 5.58342 2.82051 5.60288 2.81445 5.62242C3.14624 5.52195 3.49155 5.4563 3.8411 5.4115C4.74142 5.29607 5.8792 5.29613 7.20089 5.2962H12.9443C14.266 5.29613 15.4038 5.29607 16.3041 5.4115C16.6536 5.4563 16.999 5.52195 17.3307 5.62242C17.3247 5.60288 17.3184 5.58342 17.3117 5.56407C16.9946 4.6357 16.0444 3.93591 14.8855 3.93591H5.25964Z"
//                       fill="#800080"
//                       fillOpacity="0.5"
//                     />
//                     <path
//                       fillRule="evenodd"
//                       clipRule="evenodd"
//                       d="M12.7723 6.28503H7.22634C4.414 6.28503 3.00782 6.28503 2.21795 7.10739C1.42807 7.92975 1.61392 9.20025 1.98561 11.7413L2.33808 14.1509C2.62957 16.1437 2.77531 17.14 3.52297 17.7367C4.27062 18.3333 5.37335 18.3333 7.5788 18.3333H12.4198C14.6253 18.3333 15.7281 18.3333 16.4758 17.7367C17.2234 17.14 17.3691 16.1437 17.6606 14.1509L18.0131 11.7413C18.3848 9.20033 18.5706 7.92974 17.7808 7.10739C16.9908 6.28503 15.5847 6.28503 12.7723 6.28503ZM12.1503 13.1618C12.6157 12.8734 12.6157 12.1266 12.1503 11.8382L9.34068 10.0963C8.88843 9.816 8.33268 10.1809 8.33268 10.7583V14.2418C8.33268 14.8191 8.88843 15.184 9.34068 14.9037L12.1503 13.1618Z"
//                       fill="#800080"
//                       fillOpacity="0.5"
//                     />
//                   </svg> */}
//                   <span>
//                     Choose Any <b>4 or 4+ Courses</b>{" "}
//                   </span>
//                 </div>
//               </div>
//               <div className="ocs-lb">
//                 <div className="ocs-lbt">
//                   ₹ 1499 <span>₹ 2796</span>
//                 </div>
//                 <div className="ocs-lbb">
//                   <b> Avail 100% REFUND in 1 year</b>
//                 </div>
//               </div>
//             </div>
//             <div className="ocs-right">
//               <div className="ocs-disc">45% OFF</div>
//               <img src={ocs1} alt="ocs1" />
//             </div>
//           </div>
//           <div
//             className="ocs-cta"
//             onClick={() =>
//               (window.location.href = `${window.location.origin}/category/customizepack`)
//             }
//           >
//             Know More
//           </div>
//         </div>
//         <div className="ocs-card">
//           <div className="ocs-card-inner">
//             <div className="ocs-left">
//               <div className="ocs-lt">
//                 All Access course Pack
//                 <div className="ocs-lt-det">
//                   {/* <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="20"
//                     height="20"
//                     viewBox="0 0 20 20"
//                     fill="none"
//                   >
//                     <path
//                       d="M7.09243 1.66663H12.9092C13.1029 1.66658 13.2514 1.66655 13.3813 1.67925C14.3044 1.76956 15.0601 2.32461 15.3805 3.07227H4.62109C4.94152 2.32461 5.69715 1.76956 6.62034 1.67925C6.75017 1.66655 6.8987 1.66658 7.09243 1.66663Z"
//                       fill="#800080"
//                       fillOpacity="0.5"
//                     />
//                     <path
//                       d="M5.25964 3.93591C4.10079 3.93591 3.15057 4.6357 2.83346 5.56407C2.82685 5.58342 2.82051 5.60288 2.81445 5.62242C3.14624 5.52195 3.49155 5.4563 3.8411 5.4115C4.74142 5.29607 5.8792 5.29613 7.20089 5.2962H12.9443C14.266 5.29613 15.4038 5.29607 16.3041 5.4115C16.6536 5.4563 16.999 5.52195 17.3307 5.62242C17.3247 5.60288 17.3184 5.58342 17.3117 5.56407C16.9946 4.6357 16.0444 3.93591 14.8855 3.93591H5.25964Z"
//                       fill="#800080"
//                       fillOpacity="0.5"
//                     />
//                     <path
//                       fillRule="evenodd"
//                       clipRule="evenodd"
//                       d="M12.7723 6.28503H7.22634C4.414 6.28503 3.00782 6.28503 2.21795 7.10739C1.42807 7.92975 1.61392 9.20025 1.98561 11.7413L2.33808 14.1509C2.62957 16.1437 2.77531 17.14 3.52297 17.7367C4.27062 18.3333 5.37335 18.3333 7.5788 18.3333H12.4198C14.6253 18.3333 15.7281 18.3333 16.4758 17.7367C17.2234 17.14 17.3691 16.1437 17.6606 14.1509L18.0131 11.7413C18.3848 9.20033 18.5706 7.92974 17.7808 7.10739C16.9908 6.28503 15.5847 6.28503 12.7723 6.28503ZM12.1503 13.1618C12.6157 12.8734 12.6157 12.1266 12.1503 11.8382L9.34068 10.0963C8.88843 9.816 8.33268 10.1809 8.33268 10.7583V14.2418C8.33268 14.8191 8.88843 15.184 9.34068 14.9037L12.1503 13.1618Z"
//                       fill="#800080"
//                       fillOpacity="0.5"
//                     />
//                   </svg> */}
//                   <span>
//                     Choose All The <b> Courses</b>{" "}
//                   </span>
//                 </div>
//               </div>
//               <div className="ocs-lb">
//                 <div className="ocs-lbt">
//                   ₹ 4499 <span>₹ 14,679</span>
//                 </div>
//                 <div className="ocs-lbb">
//                   <b> Avail 100% REFUND in Lifetime</b>
//                 </div>
//               </div>
//             </div>
//             <div className="ocs-right">
//               <div className="ocs-disc  red">45% OFF</div>
//               <img src={ocs2} alt="ocs1" />
//             </div>
//           </div>
//           <div
//             className="ocs-cta"
//             onClick={() =>
//               (window.location.href = `${window.location.origin}/category/allaccess`)
//             }
//           >
//             Know More
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default OtherCourses;

import React, { useEffect, useState } from "react";
import "./Styles/OtherCourses.css";
import useCart from "../contexts/Cart";
import { useNavigate } from "react-router-dom";
import courses from "../contents/Courses.json";
import Cookies from "js-cookie";

const OtherCourses = ({ hideHeader, hide, upg, setRedirect, setShowLogin }) => {
  const { setSelectedCategory, setSelectedCourse } = useCart();
  const navigate = useNavigate();
  const [name, setName] = useState(Cookies.get("user_name"));
  useEffect(() => {
    setName(Cookies.get("user_name"));
  }, []);
  return (
    <div className="ocs-cont">
      {!hideHeader && (
        <div className="headings">
          Interested In Our <br />
          <span className="headings-col">Other Courses Too ? </span>
        </div>
      )}
      <div className="ocs-inner-cont ">
        <div className={`nocs-card ${hide === 1 && "nocs-card-hide"}`}>
          <div className="nocs-card-top">
            <div className="nocs-ct-l">
              Customized course <br />
              Pack
              <div className="nocs-ctl-b">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M7.09243 1.667H12.9092C13.1029 1.66695 13.2514 1.66691 13.3813 1.67961C14.3044 1.76992 15.0601 2.32497 15.3805 3.07264H4.62109C4.94152 2.32497 5.69715 1.76992 6.62034 1.67961C6.75017 1.66691 6.8987 1.66695 7.09243 1.667Z"
                    fill="#992E9D"
                  />
                  <path
                    d="M5.25964 3.93555C4.10079 3.93555 3.15057 4.63534 2.83346 5.5637C2.82685 5.58305 2.82051 5.60251 2.81445 5.62206C3.14624 5.52158 3.49155 5.45594 3.8411 5.41113C4.74142 5.29571 5.8792 5.29576 7.20089 5.29584H12.9443C14.266 5.29576 15.4038 5.29571 16.3041 5.41113C16.6536 5.45594 16.999 5.52158 17.3307 5.62206C17.3247 5.60251 17.3184 5.58305 17.3117 5.5637C16.9946 4.63534 16.0444 3.93555 14.8855 3.93555H5.25964Z"
                    fill="#992E9D"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.7723 6.28516H7.22634C4.414 6.28516 3.00782 6.28516 2.21795 7.10751C1.42807 7.92987 1.61392 9.20037 1.98561 11.7415L2.33808 14.151C2.62957 16.1438 2.77531 17.1401 3.52297 17.7368C4.27062 18.3335 5.37335 18.3335 7.5788 18.3335H12.4198C14.6253 18.3335 15.7281 18.3335 16.4758 17.7368C17.2234 17.1401 17.3691 16.1438 17.6606 14.151L18.0131 11.7415C18.3848 9.20046 18.5706 7.92986 17.7808 7.10751C16.9908 6.28516 15.5847 6.28516 12.7723 6.28516ZM12.1503 13.162C12.6157 12.8735 12.6157 12.1267 12.1503 11.8383L9.34068 10.0965C8.88843 9.81612 8.33268 10.181 8.33268 10.7584V14.2419C8.33268 14.8192 8.88843 15.1841 9.34068 14.9038L12.1503 13.162Z"
                    fill="#992E9D"
                  />
                </svg>
                Choose Any <b>4 or 4+ Courses</b>
              </div>
            </div>
            <div className="nocs-ctr">New</div>
          </div>

          <div className="nocs-card-mid">Avail 100% REFUND in 1 year</div>
          <div className="nocs-card-btm">
            <div className="nocs-cb-l">
              <div className="nocs-cb-ltt">
                ₹1,499 <span>₹2,796</span>
              </div>
              <div className="nocs-cb-ltb">You get 45% off</div>
            </div>
            <div
              className="nocs-cb-r"
              onClick={() =>
                (window.location.href = `${window.location.origin}/category/customizepack`)
              }
            >
              Know More
            </div>
          </div>
        </div>
        <div className={`nocs-card ${hide === 2 && "nocs-card-hide"}`}>
          <div className="nocs-card-top">
            <div className="nocs-ct-l">
              All Access <br />
              course Pack
              <div className="nocs-ctl-b">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M7.09243 1.667H12.9092C13.1029 1.66695 13.2514 1.66691 13.3813 1.67961C14.3044 1.76992 15.0601 2.32497 15.3805 3.07264H4.62109C4.94152 2.32497 5.69715 1.76992 6.62034 1.67961C6.75017 1.66691 6.8987 1.66695 7.09243 1.667Z"
                    fill="#992E9D"
                  />
                  <path
                    d="M5.25964 3.93555C4.10079 3.93555 3.15057 4.63534 2.83346 5.5637C2.82685 5.58305 2.82051 5.60251 2.81445 5.62206C3.14624 5.52158 3.49155 5.45594 3.8411 5.41113C4.74142 5.29571 5.8792 5.29576 7.20089 5.29584H12.9443C14.266 5.29576 15.4038 5.29571 16.3041 5.41113C16.6536 5.45594 16.999 5.52158 17.3307 5.62206C17.3247 5.60251 17.3184 5.58305 17.3117 5.5637C16.9946 4.63534 16.0444 3.93555 14.8855 3.93555H5.25964Z"
                    fill="#992E9D"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.7723 6.28516H7.22634C4.414 6.28516 3.00782 6.28516 2.21795 7.10751C1.42807 7.92987 1.61392 9.20037 1.98561 11.7415L2.33808 14.151C2.62957 16.1438 2.77531 17.1401 3.52297 17.7368C4.27062 18.3335 5.37335 18.3335 7.5788 18.3335H12.4198C14.6253 18.3335 15.7281 18.3335 16.4758 17.7368C17.2234 17.1401 17.3691 16.1438 17.6606 14.151L18.0131 11.7415C18.3848 9.20046 18.5706 7.92986 17.7808 7.10751C16.9908 6.28516 15.5847 6.28516 12.7723 6.28516ZM12.1503 13.162C12.6157 12.8735 12.6157 12.1267 12.1503 11.8383L9.34068 10.0965C8.88843 9.81612 8.33268 10.181 8.33268 10.7584V14.2419C8.33268 14.8192 8.88843 15.1841 9.34068 14.9038L12.1503 13.162Z"
                    fill="#992E9D"
                  />
                </svg>
                Choose <b>all the Courses</b>
              </div>
            </div>
            <div className="nocs-ctr ctr-pop">Popular</div>
          </div>

          <div className="nocs-card-mid">Avail 100% REFUND in Lifetime</div>
          <div className="nocs-card-btm">
            <div className="nocs-cb-l">
              {upg ? (
                <div className="nocs-cb-ltt">
                  ₹3,799 <span>₹ 16,077</span>
                </div>
              ) : (
                <div className="nocs-cb-ltt">
                  ₹4,499 <span>₹ 14,679</span>
                </div>
              )}
              <div className="nocs-cb-ltb">You get 45% off</div>
            </div>
            <div
              className="nocs-cb-r"
              onClick={() => {
                if (upg) {
                  setSelectedCategory("upgrade");
                  setSelectedCourse((prev) => ({
                    ...prev,
                    upgrade: courses,
                  }));
                  if (!name) {
                    setRedirect("/packpayment");

                    setShowLogin(true);
                  } else {
                    navigate(`/packpayment`);
                  }
                } else {
                  window.location.href = `${window.location.origin}/category/allaccess`;
                }
              }}
            >
              Know More
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherCourses;
