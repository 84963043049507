import React, { useEffect, useRef, useState } from "react";
import Hero from "../Components/Hero";
// import WhyRefund from "../Components/WhyRefund";
// import HowRefund from "../Components/HowRefund";
// import UnlockCourse from "../Components/UnlockCourse";
import Curriculum from "../Components/Curriculum";
import Certificate from "../Components/Certificate";
// import CaseStudies from "../Components/CaseStudies";
// import Upsell from "../Components/Upsell";
import Experiences from "../Components/Experiences";
// import Reviews from "../Components/Reviews";
import IntroFaq from "../Components/IntroFaq";
import IntroFooter from "../Components/IntroFooter";
import Cookies from "js-cookie";
import Login from "../Components/Login";
import SignUp from "../Components/SignUp";
import { useNavigate, useParams } from "react-router-dom";

import contents from "../contents/Contents.json";
import { Helmet } from "react-helmet";
import useCart from "../contexts/Cart";
import CourseFeatures from "../Components/CourseFeatures";
// import MentorSupport from "../Components/MentorSupport";
// import LimitedOffer from "../Components/LimitedOffer";
import WhyChooseUs from "../Components/WhyChooseUs";
import OtherCourses from "../Components/OtherCourses";
import Layout from "../ProPacks/pages/Layout";
import ProPackCards from "../Components/HomePage/Components/ProPackCards";
import ForgotPass from "../Components/ForgotPass";
import Refund from "../Components/Refund";
import DoubtSolve from "../Components/Reusable/DoubtSolve";
import IframeVideo from "../Components/IframeVideo";
import VideoCarousel from "../Components/VideoCarousel";

const Category = () => {
  const [showLogin, setShowLogin] = useState(false);
  const [showSign, setShowSign] = useState(false);
  const name = Cookies.get("user_name");
  const { slug } = useParams();
  const [showForgot, setShowForgot] = useState(false);

  const [bought, setBought] = useState(false);
  const navigate = useNavigate();
  const { setSelectedCategory } = useCart();

  const ids = [
    "datascientist",
    "techgeek",
    "fullstackdev",
    "business",
    "designing",
    "appdev",
    "mernstack",
    "uxresearch",
    "webdevelopment",
    "appdevelopment",
    "ethicalhacking",
    "java",
    "dataanalytics",
    "python",
    "cpp",
    "datastructure",
    "competitiveprogramming",
    "aftereffects",
    "photoshop",
    "premierepro",
    "digitalmarketing",
    "fundamentalanalysis",
    "technicalanalysis",
    "illustrator",
    "financialmodelling",
    "datascience",
    "uiux",
    "ml",
    "reactnative",
    "riskmanagement",
    "dbms",
    "devops",
    "reactjs",
    "powerbi",
    "machinelearning",
    "dsawithpython",
  ];

  if (!ids.includes(slug)) {
    Cookies.remove("slug");
    alert("wrong URL!!!");
    navigate("/");
  }

  const customSlideToExploreRef = useRef(null);
  const [showFloatSelected, setShowFloatSelected] = useState(false);
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      if (customSlideToExploreRef.current) {
        const customSlideToExploreRect =
          customSlideToExploreRef.current.getBoundingClientRect();
        const scrollPosition = window.scrollY || window.pageYOffset;
        if (scrollPosition >= customSlideToExploreRect.top) {
          setShowFloatSelected(true);
        } else {
          setShowFloatSelected(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.gtag("event", "conversion", {
      send_to: "AW-711435738/CRmfCMKls7oDENrLntMC",
    });
  }, []);

  useEffect(() => {
    const email = Cookies.get("user_email");

    try {
      if (email) {
        fetch("https://api.tutedude.com/dashboard?student_id=" + email, {
          method: "GET",
        })
          .then((res) => res.json())
          .then((data) => {
            Cookies.set(
              "bought",
              JSON.stringify(data?.dashboard?.courses || []),
              {
                domain: process.env.REACT_APP_DOMAIN,
              }
            );
            if (data?.dashboard?.courses?.includes(slug)) {
              setBought(true);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    const boughtCookies = Cookies.get("bought");

    var bt = false;

    if (boughtCookies !== undefined) {
      try {
        bt = JSON.parse(boughtCookies).includes(slug);
      } catch (error) {
        console.error("Error parsing JSON from cookies:", error);
      }
      setBought(bt);
    }
  }, [slug]);

  const pro = [
    "datascientist",
    "techgeek",
    "fullstackdev",
    "business",
    "designing",
    "appdev",
  ];

  if (pro.includes(slug)) {
    return (
      <Layout
        setShowSign={setShowSign}
        redirect={redirect}
        setShowLogin={setShowLogin}
        slug={slug}
        showLogin={showLogin}
        showSign={showSign}
      />
    );
  }

  if (slug === "fundamentalanalysis" || slug === "technicalanalysis"){
    window.location.href = "https://upskill.tutedude.com/dashboard/"
    return <></>;
  }
    return (
      <>
        <Helmet>
          <title>{contents[slug]?.title}</title>
        </Helmet>
        {showSign && (
          <SignUp
            setShowSign={setShowSign}
            redirect={redirect}
            setShowLogin={setShowLogin}
          />
        )}

        {showLogin && (
          <Login
            setBought={setBought}
            slug={slug}
            redirect={redirect}
            setShowLogin={setShowLogin}
            setShowSign={setShowSign}
            setShowForgot={setShowForgot}
          />
        )}
        {showForgot && <ForgotPass setShowForgot={setShowForgot} />}
        {showFloatSelected && (
          <div className="mobile-floater">
            {bought ? (
              <div
                className="mobile-floater-inner"
                onClick={() =>
                  window.open("https://upskill.tutedude.com/dashboard")
                }
              >
                Go to Dashboard
              </div>
            ) : (
              <div
                className="mobile-floater-inner"
                onClick={() => {
                  setSelectedCategory(slug);
                  if (!name) {
                    setRedirect(`/payment`);
                    setShowSign(true);
                  } else {
                    navigate(`/payment`);
                  }
                }}
              >
                Enroll Now
              </div>
            )}
          </div>
        )}
        {showFloatSelected && (
          <div className="access-chips">
            {/* <div
              className="access-chips-card"
              onClick={() => (window.location.href = "#how-refund")}
            >
              100% Refund
            </div>
            <div
              className="access-chips-card"
              onClick={() => (window.location.href = "#certificate")}
            >
              Certificate
            </div>
            <div
              className="access-chips-card"
              onClick={() => (window.location.href = "#mentor")}
            >
              Mentorship
            </div>
            <div
              className="access-chips-card"
              onClick={() => (window.location.href = "#curric")}
            >
              Curriculum
            </div> */}
            <div
              className="access-chips-card"
              onClick={() => navigate("/#courses")}
            >
              Explore Other courses
            </div>
            <div
              className="access-chips-card"
              onClick={() => (window.location.href = "#combo")}
            >
              Combo Packs
            </div>
          </div>
        )}
        <Hero
          setShowLogin={setShowLogin}
          setShowSign={setShowSign}
          bought={bought}
          setRedirect={setRedirect}
          cont={contents[slug]}
          slug={slug}
        />
        <div ref={customSlideToExploreRef}>
          <CourseFeatures introStats={contents[slug].introStats} />
        </div>

        {/* <LimitedOffer
        bought={bought}
        setShowLogin={setShowSign}
        slug={slug}
        setRedirect={setRedirect}
        topics1={contents[slug].topics1}
        topics2={contents[slug].topics2}
        details={contents[slug].details}
      /> */}
        <Refund price="699" months="3" />
        <IframeVideo cont={contents[slug]} />
        <Curriculum
          bought={bought}
          setShowLogin={setShowSign}
          slug={slug}
          setRedirect={setRedirect}
          topics1={contents[slug].topics1}
          topics2={contents[slug].topics2}
          details={contents[slug].details}
        />
        <Certificate name={contents[slug].name} />
        <ProPackCards slug={slug} />
        <OtherCourses hideHeader={true} />

        {/* <MentorSupport /> */}
        <DoubtSolve />
        <WhyChooseUs />
        <VideoCarousel slug={slug} />
        <Experiences />
        <IntroFaq />
        <IntroFooter />
      </>
    );
};

export default Category;
