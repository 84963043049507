import React, { useEffect, useState } from "react";
import "../CustomPack/styles/FloatSelected.css";
import useCart from "../../contexts/Cart";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
const FloatSelectedUpgrade = ({ setShowLogin, setRedirect }) => {
  const [price, setPrice] = useState(0);
  const { selectedCourse } = useCart();
  const [name, setName] = useState(Cookies.get("user_name"));
  const { setSelectedCategory } = useCart();
  useEffect(() => {
    setName(Cookies.get("user_name"));
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    if (selectedCourse?.upgrade?.length !== 0) {
      let newPrice;
      if (selectedCourse?.upgrade?.length < 3) {
        newPrice = selectedCourse?.upgrade?.length * 699;
      } else if (selectedCourse?.upgrade?.length === 3) {
        newPrice = 799;
      } else {
        newPrice = 799 + (selectedCourse?.upgrade?.length - 3) * 375;
      }
      if (newPrice) setPrice(Math.min(newPrice, 4499));
      else setPrice(0);
    } else {
      setPrice(0);
    }
  }, [selectedCourse]);
  return (
    <div className="float-s-cont">
      <div className="float-s-inner-cont">
        <div className="flc-course-cont">
          {selectedCourse?.upgrade?.length || 0}
          <span>Selected Courses</span>
        </div>
        <div className="flc-price-cont">
          ₹ {price} <span>Total Price</span>
        </div>
        {selectedCourse?.upgrade?.length > 0 ? (
          <div
            className="flc-cta pointer"
            onClick={() => {
              setSelectedCategory("upgrade");
              if (!name) {
                if (selectedCourse?.upgrade?.length > 0) {
                  setRedirect("/packpayment");
                }
                setShowLogin(true);
              } else {
                if (selectedCourse?.upgrade?.length > 0) {
                  navigate(`/packpayment`);
                }
              }
            }}
          >
            Proceed
          </div>
        ) : (
          <div className="flc-cta pointer">Add Course</div>
        )}
      </div>
    </div>
  );
};

export default FloatSelectedUpgrade;
