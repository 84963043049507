
import "./styles/Slider.css";

import Main1 from "../../../propack/assets/assets/Main1.svg";
import dataanalytics from "../../../propack/assets/assets/dataanalytics.png";
import Main2 from "../../../propack/assets/assets/Main2.png";
import Main3 from "../../../propack/assets/assets/Main3.png";
import Main4 from "../../../propack/assets/assets/Main 4.png";
import Main5 from "../../../propack/assets/assets/Main 5.png";
import Main6 from "../../../propack/assets/assets/Main 6.png";
import Icon11 from "../../../propack/assets/assets/Icon11.png";
import Icon12 from "../../../propack/assets/assets/Icon12.png";
import Icon13 from "../../../propack/assets/assets/Icon13.png";
import Icon21 from "../../../propack/assets/assets/Icon21.png";
import Icon22 from "../../../propack/assets/assets/Icon22.png";
import Icon23 from "../../../propack/assets/assets/Icon23.png";
import Icon24 from "../../../propack/assets/assets/Icon24.png";
import Icon25 from "../../../propack/assets/assets/Icon25.png";
import Icon31 from "../../../propack/assets/assets/Icon31.png";
import Icon32 from "../../../propack/assets/assets/Icon32.png";
import Icon33 from "../../../propack/assets/assets/Icon33.png";
import Icon34 from "../../../propack/assets/assets/Icon34.png";
import Icon35 from "../../../propack/assets/assets/Icon35.png";
import Icon41 from "../../../propack/assets/assets/Icon41.png";
import Icon42 from "../../../propack/assets/assets/Icon42.png";
import Icon43 from "../../../propack/assets/assets/Icon43.png";
import Icon44 from "../../../propack/assets/assets/Icon44.png";
import Icon45 from "../../../propack/assets/assets/Icon45.png";
import Icon46 from "../../../propack/assets/assets/Icon46.png";
import Icon51 from "../../../propack/assets/assets/Icon51.png";
import Icon52 from "../../../propack/assets/assets/Icon52.png";
import Icon53 from "../../../propack/assets/assets/Icon53.png";
import Icon54 from "../../../propack/assets/assets/Icon54.png";
import Icon61 from "../../../propack/assets/assets/Icon61.png";
import Icon62 from "../../../propack/assets/assets/Icon62.png";
import Icon63 from "../../../propack/assets/assets/Icon63.png";
import Icon64 from "../../../propack/assets/assets/Icon64.png";
import Icon65 from "../../../propack/assets/assets/Icon65.png";
import Icon66 from "../../../propack/assets/assets/Icon66.png";
import user from "../../../propack/assets/assets/user.png";

import ProPackCardSingle from "./ProPackCardSingle";

const SliderPro = ({ slug }) => {
  const ids = {
    datascientist: [5, 4, 2, 0, 3],
    techgeek: [5, 1, 2, 0, 3],
    fullstackdev: [4, 1, 2, 0, 3],
    business: [5, 4, 1, 2, 0],
    designing: [5, 4, 1, 2, 3],
    appdev: [5, 4, 1, 0, 3],
    powerbi:[1, 4, 5, 2, 0, 3],
    mernstack: [5, 4, 1, 2, 0, 3],
    webdevelopment: [5, 4, 1, 2, 0, 3],
    appdevelopment: [2, 4, 5, 1, 0, 3],
    ethicalhacking: [5, 4, 1, 2, 0, 3],
    java: [2, 4, 5, 1, 0, 3],
    python: [1, 4, 5, 2, 0, 3],
    cpp: [5, 4, 1, 2, 0, 3],
    datastructure: [5, 4, 1, 2, 0, 3],
    competitiveprogramming: [5, 4, 1, 2, 0, 3],
    aftereffects: [0, 5, 4, 3, 1, 2],
    photoshop: [0, 5, 4, 3, 1, 2],
    premierepro: [0, 5, 4, 3, 1, 2],
    digitalmarketing: [3, 0, 4, 5, 1, 2],
    fundamentalanalysis: [3, 0, 4, 5, 1, 2],
    technicalanalysis: [3, 0, 4, 5, 1, 2],
    illustrator: [0, 5, 4, 3, 1, 2],
    financialmodelling: [3, 0, 4, 5, 1, 2],
    datascience: [1, 4, 5, 2, 0, 3],
    dataanalytics: [1, 4, 5, 2, 0, 3],
    uiux: [0, 5, 4, 3, 1, 2],
    uxresearch: [0, 5, 4, 3, 1, 2],
    reactnative: [2, 4, 5, 1, 0, 3],
    riskmanagement: [3, 0, 4, 5, 1, 2],
    dbms: [5, 4, 1, 2, 0, 3],
    devops: [4, 1, 5, 2, 0, 3],
    reactjs: [5, 4, 1, 2, 0, 3],
    machinelearning: [1, 4, 5, 2, 0, 3],
    dsawithpython: [1, 4, 5, 2, 0, 3],
  };
  const other = [5, 0, 3, 1, 4, 2];

  const cards = [
    {
      icon: Main1,
      slug: "designing",
      heading: "Creative Designer Combo pack",
      company: [
        {
          icon: Icon11,
          title: "Adobe Illustrator",
        },
        {
          icon: Icon12,
          title: "Adobe Photoshop",
        },
        {
          icon: Icon13,
          title: "UI/UX",
        },
        {
          icon: user,
          title: "UX Research",
        },
      ],
      price: "₹1,499",
      discount: "₹2,796",
      off: "40% OFF ",
    },
    {
      icon: Main2,
      slug: "datascientist",
      heading: "Data Scientist Combo Pack",
      company: [
        {
          icon: Icon25,
          title: "Data Science",
        },
        {
          icon: dataanalytics,
          title: "Data Analytics",
        },
        {
          icon: Icon21,
          title: "SQL",
        },
        {
          icon: Icon22,
          title: "Machine learning",
        },
        {
          icon: Icon23,
          title: "Python",
        },
        {
          icon: Icon24,
          title: "DSA With Python",
        },
      ],
      price: "₹1,999",
      discount: "₹2,796",
      off: "40% OFF ",
    },
    {
      slug: "appdev",
      icon: Main3,
      heading: "App developer Combo pack",
      company: [
        {
          icon: Icon31,
          title: "JAVA",
        },
        {
          icon: Icon32,
          title: "Flutter",
        },
        {
          icon: Icon33,
          title: "React Native",
        },
        {
          icon: Icon34,
          title: "React JS",
        },
        {
          icon: Icon35,
          title: "App develop",
        },
      ],
      price: "₹1,999",
      discount: "₹2,796",
      off: "40% OFF ",
    },

    {
      icon: Main5,
      slug: "business",
      heading: "Business Combo pack",
      company: [
        {
          icon: Icon51,
          title: "Stock Market",
        },
        {
          icon: Icon52,
          title: "FRM",
        },
        {
          icon: Icon53,
          title: "Technical Research",
        },
        {
          icon: Icon54,
          title: "Financial Modelling valuation",
        },
      ],
      price: "₹1,499",
      discount: "₹2,796",
      off: "40% OFF ",
    },

    {
      icon: Main6,
      slug: "techgeek",
      heading: "Tech Geek Combo pack",
      company: [
        {
          icon: Icon61,
          title: "Python",
        },
        {
          icon: Icon62,
          title: "App develop",
        },
        {
          icon: Icon63,
          title: "Ethical Hacking",
        },
        {
          icon: Icon64,
          title: "JAVA",
        },
        {
          icon: Icon65,
          title: "Data Science",
        },
        {
          icon: Icon66,
          title: "MERN",
        },
      ],
      price: "₹1,999",
      discount: "₹2,796",
      off: "40% OFF ",
    },
    {
      icon: Main4,
      slug: "fullstackdev",
      heading: "Full Stack Combo pack",
      company: [
        {
          icon: Icon41,
          title: "MERN",
        },
        {
          icon: Icon42,
          title: "React JS",
        },
        {
          icon: Icon43,
          title: "DSA With Python",
        },
        {
          icon: Icon44,
          title: "DevOPS",
        },
        {
          icon: Icon45,
          title: "Python",
        },
        {
          icon: Icon46,
          title: "DBMS",
        },
      ],
      price: "₹1,999",
      discount: "₹2,796",
      off: "40% OFF ",
    },
  ];

  return (
    <div className="parent parent1">
      <div className="cardssss-cont">
        {slug
          ? ids[slug]?.map((card, index) => {
              return (
                <ProPackCardSingle
                  key={index}
                  index={index}
                  card={cards[card]}
                />
              );
            })
          : other?.map((card, index) => {
              return (
                <ProPackCardSingle
                  key={index}
                  index={index}
                  card={cards[card]}
                />
              );
            })}
      </div>
    </div>
  );
};
export default SliderPro;
