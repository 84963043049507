// import React, { useEffect } from "react";
// import "./success.css";
// import logo from "../Components/HomePage/Assests/logo.png";

// import { useNavigate, useLocation } from "react-router-dom";
// import { Helmet } from "react-helmet";
// import Cookies from "js-cookie";
// const Success = () => {
// const navigate = useNavigate();
// const location = useLocation();
// useEffect(() => {
//   const params = new URLSearchParams(location.search);
//   const amt = params.get("amt");
//   const amount = Cookies.get("amt");
//   if (amount) {
//     window.fbq("track", "Purchase", { currency: "INR", value: amount });
//     window.gtag("event", "conversion", {
//       send_to: "AW-711435738/UBkMCNqpn_MCENrLntMC",
//       value: amount,
//       currency: "INR",
//       transaction_id: "",
//     });
//   }
//   if (amt) {
//     Cookies.set("amt", amt, { expires: 2 / (24 * 60) });
//     navigate("/letskillit");
//   }
// }, [location.search, navigate]);
//   return (
//     <>
//       <Helmet>
//         <title>Tutedude</title>
//       </Helmet>
//       <div>
//         <div className="text-center">
//           <img className="img-responsive " src={logo} alt="Chania" />
//         </div>

//         <div className="text-center">
//           <h2 className="payment">Payment Successful!</h2>
//         </div>

//         <div className="jumbotron text-center ">
//           <h2
//             className="display-3  text-white"
//             style={{ fontFamily: "emoji", fontSize: "3.5rem" }}
//           >
//             Happy learning!
//           </h2>
//           <p className="lead text-white">
//             Please practice along with your instructor, this is a major benefit
//             of self paced courses. Wherever you face any doubt just ping to your
//             mentor on the chat box mentioned on the course page. Also submit all
//             your assignments and exercises to the mentor and we will provide you
//             with a feedback. At the end of the course you can ask your mentor
//             for the certificate. All the best for your learning experience.
//           </p>
//           <hr />

//           <div className="lead">
//             <div
//               className="btn btn-primary btn-sm"
//               onClick={() => navigate("/")}
//             >
//               Continue to homepage
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Success;

import React, { useEffect, useState } from "react";
import styles from "./ModalComponent.module.css";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const ModalComponent = () => {
  const [modalVisible, setModalVisible] = useState(true);
  const [animate, setAnimate] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const amt = params.get("amt");
    const amount = Cookies.get("amt");
    const pid = params.get("pid");
    const transaction_id = Cookies.get("pid");
    if (amount) {
      window.fbq("track", "Purchase", { currency: "INR", value: amount });
      window.gtag("event", "conversion", {
        send_to: "AW-711435738/HwkuCMils7oDENrLntMC",
        value: amount,
        currency: "INR",
        transaction_id: transaction_id,
      });
    }
    if (amt) {
      Cookies.set("amt", amt, { expires: 2 / (24 * 60) });
      Cookies.set("pid", pid, { expires: 2 / (24 * 60) });
      navigate("/letskillit");
    }
  }, [location.search, navigate]);

  const closeModal = () => {
    setAnimate(true);
    setTimeout(() => {
      setModalVisible(false);
    }, 1000);
  };

  return (
    <>
      <Helmet>
        <title>Tutedude</title>
        <style>
          {`
            html {
              font-size: 62.5%;
              font-family: "Poppins", sans-serif;
              text-align: center;
              scroll-behavior: smooth;
            }
            @media (max-width: 970px) {
  html {
    font-size: 55%;
  }
}
@media (max-width: 500px) {
  html {
    font-size: 62.5%;
  }}
  @media (max-width: 390px) {
  html {
    font-size: 55%;
  }
}

          `}
        </style>
      </Helmet>
      <div>
        {modalVisible && (
          <div
            id="add-modal"
            className={`${styles.addModal} ${styles.addModal} ${
              animate && styles.animateModal
            }`}
          >
            <div className={styles.addModalContent}>
              <button
                id="ok"
                className={`${styles.okButton} ${styles.button}`}
                onClick={closeModal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.6rem"
                  height="1.1rem"
                  viewBox="0 0 16 11"
                  fill="none"
                >
                  <path
                    d="M14.12 0.453369L8 6.56004L1.88 0.453369L0 2.33337L8 10.3334L16 2.33337L14.12 0.453369Z"
                    fill="#800080"
                  />
                </svg>
              </button>
              <span>Last Chance to Grab</span>
              <h3 className={styles.h3}>
                You can add 3 more courses of your choice at just ₹ 799
              </h3>
              <div className={styles.addFlex}>
                <ol className={styles.addFlexOl}>
                  Benefits of this smart value:
                  <li className={styles.addFlexLi}>
                    3 extra courses of your choice
                  </li>
                  <li className={styles.addFlexLi}>
                    Feesback validity increase from 3 months to 1 year
                  </li>
                  <li className={styles.addFlexLi}>
                    Lifetime access on all the extra courses
                  </li>
                  <li className={styles.addFlexLi}>
                    Mentorship facility on each course
                  </li>
                </ol>
                <a href="category/upgrade">
                  <button
                    className={`${styles.addFlexButton} ${styles.button}`}
                  >
                    Click to view the offer now
                  </button>
                </a>
              </div>
            </div>
          </div>
        )}
        <header>
          <img
            src="https://tutedude.in/refundassets/Tutedude.png"
            alt="Tutedude Logo"
          />
        </header>
        <div className={styles.success}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="5rem"
            height="5rem"
            viewBox="0 0 50 50"
            fill="none"
            className={styles.successSvg}
          >
            <circle cx="25" cy="25" r="25" fill="#29BF12" />
            <path
              d="M33.9197 16.7878L21.3716 29.3359L16.6015 24.5658L15.0303 26.137L21.3716 32.4784L35.4909 18.3591L33.9197 16.7878Z"
              fill="white"
            />
          </svg>
          <h1 className={styles.h1}>Payment Successful</h1>
        </div>
        <main className={styles.main}>
          <h2>Happy Learning!</h2>
          <p>
            A brief on how you can proceed to acquire the knowledge from your
            favourite course:
          </p>
          <ol>
            <li>
              Please practice along with your instructor, this is a major
              benefit of self-paced courses.
            </li>
            <li>
              In case of doubts, you can take help from your mentor by dropping
              the message in the chat box on the course page.
            </li>
            <li>
              Submit all your assignments and exercises to the mentor and we
              will provide you with feedback.
            </li>
            <li>
              At the end of the course, you can ask your mentor for the
              certificate.
            </li>
            <li>
              You will also avail the Feesback once you have completed the
              course within 3 months of enrollment
            </li>
          </ol>
          <p>All the best for your learning experience.</p>
          <a href="https://tutedude.com">
            <button className={`${styles.mainButton} ${styles.button}`}>
              Continue to Homepage
            </button>
          </a>
        </main>
      </div>
    </>
  );
};

export default ModalComponent;
