import React from "react";
import "../../styles/applybtn.css";
import useCart from "../../../contexts/Cart";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
function ApplyBtn({ classes, text, curric, price, setRedirect, setShowLogin }) {
  const { setSelectedCourse, setSelectedCategory } = useCart();
  const name = Cookies.get("user_name");
  const navigate = useNavigate();
  return (
    <p
      className={`pro-pac-apply-now-btn ${classes} pointer`}
      onClick={() => {
        setSelectedCategory("combo");
        if (!name) {
          setSelectedCourse((prev) => ({
            ...prev,
            combo: [curric, price],
          }));
          setRedirect("/packpayment");
          setShowLogin(true);
        } else {
          setSelectedCourse((prev) => ({
            ...prev,
            combo: [curric, price],
          }));
          navigate("/packpayment");
        }
      }}
    >
      {text}
    </p>
  );
}

export default ApplyBtn;
