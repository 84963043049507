import React from "react";
import { useState } from "react";
import Cookies from "js-cookie";
import "./Mlogin.css";
import logo from "../Assests/logo.svg";
// import cross from "../Assests/cross.svg";
import phone from "../Assests/Phone.svg";
import Person from "../Assests/Person.svg";
import emailImg from "../Assests/email.svg";
import pass from "../Assests/pass.svg";
import { useNavigate } from "react-router-dom";

const SignUp = ({ setShowSign, setShowLogin, closeCross, redirect }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const navigate = useNavigate();
  const onSignUp = (email, password, name, phone) => {
    console.log("onLogin email = ", email);

    fetch("https://api.tutedude.com/signup", {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        name,
        phone,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if (data.success === "true" || data.success === true) {
          Cookies.set("user_email", email, {
            domain: process.env.REACT_APP_DOMAIN,
          });
          Cookies.set("user_pass", password, {
            domain: process.env.REACT_APP_DOMAIN,
          });
          Cookies.set("user_name", name, {
            domain: process.env.REACT_APP_DOMAIN,
          });
          window.alert("You Have Successfully Registered!");
          setShowSign(false);
          if (redirect) {
            navigate(redirect);
            return;
          }
          window.location.reload();
        } else {
          window.alert("Email Already Registerd!!! Please Log In...");
          setShowSign(false);
          setShowLogin(true);
        }
      })
      .catch((err) => {
        console.log(err);
        window.alert("Something went wrong");
      });
  };
  const handlenumchange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value.length <= 10) {
      setMobile(value);
    }
  };
  return (
    <div className="login-cont">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSignUp(email.trim(), password.trim(), name.trim(), mobile.trim());
        }}
        className="login-inner-cont"
      >
        <div className="l-logo-cont">
          {/* <div>SignUp to</div> */}
          <img src={logo} alt="logo" />
          {closeCross !== false && (
            <div
              className="l-cross-cont pointer"
              onClick={() => setShowSign(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <path
                  d="M12.5 2C9.71523 2 7.04451 3.10625 5.07538 5.07538C3.10625 7.04451 2 9.71523 2 12.5C2 15.2848 3.10625 17.9555 5.07538 19.9246C7.04451 21.8938 9.71523 23 12.5 23C15.2848 23 17.9555 21.8938 19.9246 19.9246C21.8938 17.9555 23 15.2848 23 12.5C23 9.71523 21.8938 7.04451 19.9246 5.07538C17.9555 3.10625 15.2848 2 12.5 2V2ZM18.4862 16.3262L16.325 18.4875L12.5 14.6612L8.67375 18.4862L6.5125 16.325L10.34 12.5L6.51375 8.67375L8.675 6.51375L12.5 10.3388L16.3262 6.5125L18.4875 8.67375L14.66 12.5L18.4862 16.3262Z"
                  fill="#EB0300"
                />
              </svg>
            </div>
          )}
        </div>
        <div className="inp-conts">
          <div className="inp-cont">
            <img src={Person} className="inp-img" alt="Email" />
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Full Name"
              className="l-inp"
            />
          </div>
          <div className="inp-cont">
            <img src={emailImg} className="inp-img" alt="Email" />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className="l-inp"
            />
          </div>
          <div className="inp-cont">
            <img className="inp-img" src={pass} alt="Email" />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password(min 6 letters)"
              className="l-inp"
            />
          </div>
          <div className="inp-cont">
            <img src={phone} className="inp-img" alt="Email" />
            <input
              type="tel"
              value={mobile}
              onChange={handlenumchange}
              placeholder="Phone Number"
              className="l-inp"
            />
          </div>
        </div>
        <button type="submit" className="l-btn">
          Sign Up
        </button>
        <div className="l-new">
          Already have an Account?{" "}
          <b
            className="l-sign"
            onClick={() => {
              setShowSign(false);
              setShowLogin(true);
            }}
          >
            Login
          </b>
        </div>
      </form>
    </div>
  );
};

export default SignUp;
