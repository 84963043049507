import React, { useState } from "react";
import curr1 from "../Assests/curr1.svg";
import curr2 from "../Assests/curr2.svg";
import curr3 from "../Assests/curr3.svg";
import "./Styles/IntroCurric.css";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useCart from "../contexts/Cart";
const Curriculum = ({
  bought,
  setShowLogin,
  details,
  topics2,
  setWidth=false,
  topics1,
  hideDetails,
  slug,
  setRedirect,
}) => {
  const name = Cookies.get("user_name");

  const navigate = useNavigate();
  const { setSelectedCategory } = useCart();

  return (
    <div className={`icurr-cont ${setWidth && "icurr-setWidth"}`} id="curric" >
      <div className="icurr-inner-cont">
        {!hideDetails && (
          <>
            <div className="headings-col">Course Curriculum</div>
            <div className="icurr-details-cont">
              <div className="curr-det-cont">
                <div className="icurr-det-img-cont">
                  <img className="icurr-det-img" src={curr1} alt="curr1"></img>
                </div>
                <div className="curr-det-head-cont">
                  <div className="curr-det-head">{details?.enrolled}</div>
                  <div className="curr-det-text">learners</div>
                </div>
              </div>
              <div className="curr-det-cont">
                <div className="icurr-det-img-cont">
                  <img className="icurr-det-img" src={curr2} alt="curr1"></img>
                </div>
                <div className="curr-det-head-cont">
                  <div className="curr-det-head">{details?.duration}</div>
                  <div className="curr-det-text">Content</div>
                </div>
              </div>
              <div className="curr-det-cont">
                <div className="icurr-det-img-cont">
                  <img className="icurr-det-img" src={curr3} alt="curr1"></img>
                </div>
                <div className="curr-det-head-cont">
                  <div className="curr-det-head">Simple</div>
                  <div className="curr-det-text">English</div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="icurr-main-cont">
          <div className="icurr-main-left">
            {topics1?.map((topic, i) => (
              <LectrureList key={i} topic={topic} ind={i + 1} />
            ))}
          </div>
          <div className="icurr-main-right">
            {topics2?.map((topic, i) => (
              <LectrureList
                key={i}
                topic={topic}
                ind={i + topics1.length + 1}
              />
            ))}
          </div>
        </div>

        {!hideDetails && (
          <>
            {bought ? (
              <div
                className="curr-btn"
                onClick={() =>
                  window.open("https://upskill.tutedude.com/dashboard")
                }
              >
                Got to Dashboard
              </div>
            ) : (
              <div
                className="curr-btn"
                onClick={() => {
                  setSelectedCategory(slug);
                  if (!name) {
                    setRedirect(`/payment`);
                    setShowLogin(true);
                  } else {
                    navigate(`/payment`);
                  }
                }}
              >
                Enroll Now
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const LectrureList = ({ topic, ind }) => {
  const [topicClicked, setTopicClicked] = useState(ind === 2 ? true : false);

  return (
    <div
      className="curr-90"
      style={{
        boxShadow: " 0px 1.5px 7px rgba(128, 0, 128, 0.12)",
        overflow: "hidden",
      }}
    >
      <div
        className={`ileclist-topic ${topicClicked ? "ilec-active" : ""}`}
        onClick={() => setTopicClicked(!topicClicked)}
      >
        <div className="ilec-dev-cont">
          <div className="ilec-head-cont">
            <p>{topic.section_name}</p>
          </div>
          {!topicClicked ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="12"
              viewBox="0 0 20 12"
              fill="none"
            >
              <path
                d="M19.2811 1.07704C18.617 0.467789 17.5972 0.467789 16.9331 1.07704L11.6602 5.91458C10.8954 6.61625 9.72091 6.61625 8.95608 5.91458L3.68317 1.07704C3.01909 0.46779 1.9993 0.46779 1.33522 1.07704V1.07704C0.5851 1.76523 0.585099 2.94811 1.33522 3.63629L8.95609 10.6279C9.72091 11.3296 10.8954 11.3296 11.6602 10.6279L19.2811 3.6363C20.0312 2.94811 20.0312 1.76523 19.2811 1.07704V1.07704Z"
                fill="#800080"
                fillOpacity="0.5"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="12"
              viewBox="0 0 20 12"
              fill="none"
            >
              <path
                d="M1.33515 10.7913C1.99923 11.4006 3.01901 11.4006 3.68309 10.7913L8.956 5.95381C9.72082 5.25214 10.8953 5.25214 11.6601 5.95381L16.933 10.7913C17.5971 11.4006 18.6169 11.4006 19.281 10.7913V10.7913C20.0311 10.1032 20.0311 8.92028 19.281 8.23209L11.6601 1.24047C10.8953 0.538804 9.72083 0.538805 8.956 1.24048L1.33514 8.23209C0.585018 8.92028 0.585019 10.1032 1.33515 10.7913V10.7913Z"
                fill="#800080"
                fillOpacity="0.5"
              />
            </svg>
          )}
        </div>
      </div>
      {topicClicked && (
        <div style={{ width: "100%", padding: "10px" }}>
          {topic.section_lectures.map((lec, i) => (
            <div className="ileclist-quesDiv" key={i}>
              <div className="icurr-video">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M16 10L18.5768 8.45392C19.3699 7.97803 19.7665 7.74009 20.0928 7.77051C20.3773 7.79703 20.6369 7.944 20.806 8.17433C21 8.43848 21 8.90095 21 9.8259V14.1741C21 15.099 21 15.5615 20.806 15.8257C20.6369 16.056 20.3773 16.203 20.0928 16.2295C19.7665 16.2599 19.3699 16.022 18.5768 15.5461L16 14M6.2 18H12.8C13.9201 18 14.4802 18 14.908 17.782C15.2843 17.5903 15.5903 17.2843 15.782 16.908C16 16.4802 16 15.9201 16 14.8V9.2C16 8.0799 16 7.51984 15.782 7.09202C15.5903 6.71569 15.2843 6.40973 14.908 6.21799C14.4802 6 13.9201 6 12.8 6H6.2C5.0799 6 4.51984 6 4.09202 6.21799C3.71569 6.40973 3.40973 6.71569 3.21799 7.09202C3 7.51984 3 8.07989 3 9.2V14.8C3 15.9201 3 16.4802 3.21799 16.908C3.40973 17.2843 3.71569 17.5903 4.09202 17.782C4.51984 18 5.07989 18 6.2 18Z"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <span> {lec}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Curriculum;
