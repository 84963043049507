// import "./App.css";
// import React, { useState, useEffect, Suspense } from "react";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { CartProvider } from "./contexts/Cart";
// import Main from "./Pages/Main";

// const LoadCategory = React.lazy(() => import("./Pages/Category"));
// const LoadPayment = React.lazy(() => import("./Pages/Payment"));
// const LoadCustomPack = React.lazy(() => import("./Pages/CustomPack"));
// const LoadAllAccess = React.lazy(() => import("./Pages/AllAccess"));
// const LoadTrackPages = React.lazy(() => import("./Pages/TrackPages"));
// const LoadCustomPayment = React.lazy(() => import("./Pages/CustomPayment"));
// const LoadTrackPayment = React.lazy(() => import("./Pages/TrackPayment"));
// const LoadSuccess = React.lazy(() => import("./Pages/Success"));
// const LoadUpgrade = React.lazy(() => import("./Pages/Upgrade"));

// function App() {
//   const [selectedCourse, setSelectedCourse] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState([]);

//   useEffect(() => {
//     const selectedCourses = JSON.parse(localStorage.getItem("selectedCourses"));
//     if (selectedCourses && selectedCourses.length > 0) {
//       setSelectedCourse(selectedCourses);
//     }
//     const selectedCategory = localStorage.getItem("selectedCategory");
//     if (selectedCategory) {
//       setSelectedCategory(selectedCategory);
//     }
//   }, []);

//   useEffect(() => {
//     localStorage.setItem("selectedCourses", JSON.stringify(selectedCourse));
//   }, [selectedCourse]);

//   useEffect(() => {
//     localStorage.setItem("selectedCategory", selectedCategory);
//   }, [selectedCategory]);

//   return (
//     <CartProvider
//       value={{
//         selectedCourse,
//         setSelectedCourse,
//         selectedCategory,
//         setSelectedCategory,
//       }}
//     >
//       <BrowserRouter>
//         <Routes>
//           <Route path="/">
//             <Route path="" element={<Main />} />
//             <Route
//               path="category/:slug"
//               element={
//                 <Suspense
//                   fallback={<div className="loader">Loading Page...</div>}
//                 >
//                   <LoadCategory />
//                 </Suspense>
//               }
//             />
//             <Route
//               path="category/upgrade"
//               element={
//                 <Suspense
//                   fallback={
//                     <div className="loader">Loading Upgrade Page...</div>
//                   }
//                 >
//                   <LoadUpgrade />
//                 </Suspense>
//               }
//             />
//             <Route
//               path="packpayment"
//               element={
//                 <Suspense fallback={<div>Loading Payment Page...</div>}>
//                   <LoadCustomPayment />
//                 </Suspense>
//               }
//             />

//             <Route
//               path="trackspayment"
//               element={
//                 <Suspense fallback={<div>Loading Payment Page...</div>}>
//                   <LoadTrackPayment />
//                 </Suspense>
//               }
//             />
//             <Route
//               path="payment"
//               element={
//                 <Suspense fallback={<div>Loading Payment Page...</div>}>
//                   <LoadPayment />
//                 </Suspense>
//               }
//             />
//             <Route
//               path="category/customizepack"
//               element={
//                 <Suspense fallback={<div>Loading Custom Pack...</div>}>
//                   <LoadCustomPack />
//                 </Suspense>
//               }
//             />
//             <Route
//               path="category/allaccess"
//               element={
//                 <Suspense fallback={<div>Loading All Access...</div>}>
//                   <LoadAllAccess />
//                 </Suspense>
//               }
//             />
//             <Route
//               path="tracks/:slug"
//               element={
//                 <Suspense fallback={<div>Loading Track Pages...</div>}>
//                   <LoadTrackPages />
//                 </Suspense>
//               }
//             />
//             <Route
//               path="letskillit"
//               element={
//                 <Suspense fallback={<div>Loading Success...</div>}>
//                   <LoadSuccess />
//                 </Suspense>
//               }
//             />
//           </Route>
//         </Routes>
//       </BrowserRouter>
//     </CartProvider>
//   );
// }

// export default App;

import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CartProvider } from "./contexts/Cart";
import Main from "./Pages/Main";
import Category from "./Pages/Category";
import Payment from "./Pages/Payment";
// import CustomPack from "./Pages/CustomPack";
import AllAccess from "./Pages/AllAccess";
// import TrackPages from "./Pages/TrackPages";
import CustomPayment from "./Pages/CustomPayment";
// import TrackPayment from "./Pages/TrackPayment";
import Success from "./Pages/Success";
// import Upgrade from "./Pages/Upgrade";
import TrackPaymentOld from "./Pages/TrackPaymentOld";
import Layout from "./InternshipTrack/pages/Layout";
import Layout1 from "./CustomPack/pages/Layout";
import Upgrade from "./UpgradePage/pages/Layout";
// import PaymentPage from "./Pages/NewPayment/PaymentPage";

function App() {
  const [selectedCourse, setSelectedCourse] = useState({});
  const [selectedCategory, setSelectedCategory] = useState([]);

  useEffect(() => {
    const selectedCourses = JSON.parse(localStorage.getItem("selectedCourses"));

    if (selectedCourses) {
      setSelectedCourse(selectedCourses);
    }
    const selectedCategory = localStorage.getItem("selectedCategory");
    if (selectedCategory) {
      setSelectedCategory(selectedCategory);
    }
  }, []);

  useEffect(() => {
    if (JSON.stringify(selectedCourse) !== "{}") {
      localStorage.setItem("selectedCourses", JSON.stringify(selectedCourse));
    }
  }, [selectedCourse]);

  useEffect(() => {
    localStorage.setItem("selectedCategory", selectedCategory);
  }, [selectedCategory]);

  return (
    <CartProvider
      value={{
        selectedCourse,
        setSelectedCourse,
        selectedCategory,
        setSelectedCategory,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route path="" element={<Main />} />
            <Route path="courses" element={<TrackUser />} />
            <Route path="category/:slug" element={<Category />} />
            <Route path="category/upgrade" element={<Upgrade />} />
            <Route path="packpayment" element={<CustomPayment />} />
            <Route path="trackspayment" element={<TrackPaymentOld />} />
            <Route path="payment" element={<Payment />} />
            <Route path="category/customizepack" element={<Layout1 />} />
            <Route path="category/allaccess" element={<AllAccess />} />
            <Route path="tracks/:slug" element={<Layout />} />
            <Route path="letskillit" element={<Success />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </CartProvider>
  );
}

export default App;

const TrackUser = () => {
  useEffect(() => {
    const trackUser = async () => {
      try {
        const response = await fetch(
          "https://api.tutedude.com/lms/v1/visitor",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          console.log("Loading courses...");
        } else {
          console.error("Loaded Courses");
        }
        window.location.href = "https://tutedude.com/#courses";
      } catch (error) {
        console.log("Error:", error);
      }
    };
    trackUser();
  }, []);

  return <div>Loading...</div>;
};
