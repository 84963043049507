import React from "react";
import "./Styles/WhyRefund.css";
// import whyref from "../Assests/whyref.svg";
// import play from "../Assests/play.png";
const WhyRefund = () => {
  // const [playing, setPlaying] = useState(false);
  // const [noVid, setNoVid] = useState(false);
  // const videoRef = useRef();
  return (
    <div className="why-cont" id="why-refund">
      <div className="why-upper-cont">
        <div className="yellow"></div>
        <div className="green"></div>
        <div className="why-head">Why refund?</div>
        <div className="why-text">
          A fee ensures commitment and seriousness in learning, as free things
          are often undervalued.
          {/* <div className="why-quote1">“</div>
          <div className="why-quote2">“</div> */}
        </div>
        <div className="why-video-cont">
          {/* {noVid && "Something Went Wrong !!!"}
          {!playing && (
            <div className="rm-vid-overview">
              <div
                className="rm-play"
                onClick={() => {
                  setPlaying(true);
                  videoRef.current?.play();
                }}
              >
                <img src={play} alt="rmblav" />
              </div>
            </div>
          )}
          {!noVid && (
            <video
              onError={() => {
                setNoVid(true);
                setPlaying(false);
              }}
              onPause={() => setPlaying(false)}
              ref={videoRef}
              className="rm-vid"
              controls={true}
              src="https://d1nyzl35ecsrcr.cloudfront.net/tdmw/refundvideo.mp4"
              alt="rmblav"
            />
          )} */}

          <iframe
            className="rm-vid"
            src="https://www.youtube.com/embed/hQKl0wLRE18?si=9FSN6fiKT3Q2WHni"
            title="YouTube video player"
            frameBorder="0"
            width="100%"
            height="100%"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default WhyRefund;
