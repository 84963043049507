// import dst1 from "../../assets/careers/dst1.png";
// import dst2 from "../../assets/careers/dst2.png";
import ds1 from "../../assets/careers/ds1.png";
import ds2 from "../../assets/careers/ds2.png";
import ds3 from "../../assets/careers/ds3.png";
import ds4 from "../../assets/careers/ds4.png";
import fs1 from "../../assets/careers/fs1.png";
import fs2 from "../../assets/careers/fs2.png";
import fs3 from "../../assets/careers/fs3.png";
import fs4 from "../../assets/careers/fs4.png";
import tg1 from "../../assets/careers/tg1.png";
import tg2 from "../../assets/careers/tg2.png";
import tg3 from "../../assets/careers/tg3.png";
import tg4 from "../../assets/careers/tg4.png";
import ad1 from "../../assets/careers/ad1.png";
import ad2 from "../../assets/careers/ad2.png";
import ad3 from "../../assets/careers/ad3.png";
import ad4 from "../../assets/careers/ad4.png";
import b1 from "../../assets/careers/b1.png";
import b2 from "../../assets/careers/b2.png";
import b3 from "../../assets/careers/b3.png";
import b4 from "../../assets/careers/b4.png";
import c1 from "../../assets/careers/c1.png";
import c2 from "../../assets/careers/c2.png";
import c3 from "../../assets/careers/c3.png";
import c4 from "../../assets/careers/c4.png";
import html from "../../assets/html.png";
import css from "../../assets/css.png";
import js from "../../assets/js.png";
import mongo from "../../assets/mongo.png";
import express from "../../assets/express.png";
import react from "../../assets/react.png";
import node from "../../assets/node.png";
import boots from "../../assets/boots.png";
import vsc from "../../assets/vsc.png";
import ai from "../../assets/ai.png";
import ps from "../../assets/ps.png";
import miro from "../../assets/miro.png";
import figma from "../../assets/figma.png";
import java from "../../assets/java.png";
import astd from "../../assets/astd.png";
import netbeams from "../../assets/netbeams.png";
import ecclipse from "../../assets/ecclipse.png";

import ds from "../../../Assests/Images/science.webp";
import dsa from "../../../Assests/Images/datastructure.png";
import eh from "../../../Assests/Images/hacking.webp";
import javaa from "../../../Assests/Images/java.png";
import mern from "../../../Assests/Images/mern.webp";
import fmv from "../../../Assests/Images/fmv.png";
import uxresearch from "../../../Assests/uxresearch.png";
import frm from "../../../Assests/Images/risk.webp";
import pss from "../../../Assests/Images/photoshop.jpg";
import python from "../../../Assests/Images/python.png";
import reactt from "../../../Assests/Images/reactnative.svg";
import stm from "../../../Assests/Images/fundamental.png";
import dbms from "../../../Assests/Images/dbms.jpg";
import devops from "../../../Assests/Images/devops.png";
import ml from "../../../Assests/Images/ml.webp";
import ad from "../../../Assests/Images/app.svg";
import tecr from "../../../Assests/Images/technical.png";
import dataanalytics from "../../../Assests/Images/dataanalytics.png";
import aii from "../../../Assests/Images/illustrator.webp";
import flutter from "../../../propack/assets/assets/Icon32.png";
const data = {
  datascientist: {
    price: 1999,
    pagetitle: "Data Science Pack",
    heroHead: "Data Science",
    curric: [
      {
        title: "Data Science",
        url: "datascience",
        duration: "35hr 10min",
        projects: "7",
        src: ds,
      },
      {
        title: "Data Analysis",
        url: "dataanalytics",
        duration: "25hr 10min",
        projects: "7",
        src: dataanalytics,
      },
      {
        title: "Python",
        url: "python",
        duration: "35hr 10min",
        projects: "7",
        src: python,
      },
      {
        title: "Data Structure & Algorithm",
        url: "datastructure",
        duration: "35hr 10min",
        projects: "7",
        src: dsa,
      },
      {
        title: "DBMS with SQL",
        url: "dbms",
        duration: "35hr 10min",
        projects: "7",
        src: dbms,
      },
      {
        title: "Machine Learning",
        url: "machinelearning",
        duration: "35hr 10min",
        projects: "7",
        src: ml,
      },
    ],
    careers: [
      {
        title: "Data Analyst ",
        img: ds1,
        desc: "Understand business problems and use your technical & analytical skills to solve them with the help of data.",
      },
      {
        title: "Machine learning Engineer",
        img: ds2,
        desc: "Create and maintain rich interactive visualizations through data interpretation and analysis.",
      },
      {
        title: "Business Analyst",
        img: ds3,
        desc: "Uses domain knowledge and technical aspects of the data industry to drive business decisions.",
      },
      {
        title: "Data Scientist",
        img: ds4,
        desc: "Design, develop and test machine learning systems and implement ML algorithms.",
      },
    ],
    // tools: [dst1, dst2],
  },
  techgeek: {
    pagetitle: "Tech Geeks Pack",
    price: 1999,
    heroHead: "Tech Geeks",
    curric: [
      {
        title: "Python",
        url: "python",
        duration: "35hr 10min",
        projects: "7",
        src: python,
      },
      {
        title: "App Development",
        url: "appdevelopment",
        duration: "35hr 10min",
        projects: "7",
        src: ad,
      },
      {
        title: "Ethical Hacking",
        url: "ethicalhacking",
        duration: "35hr 10min",
        projects: "7",
        src: eh,
      },
      {
        title: "Java",
        url: "java",
        duration: "35hr 10min",
        projects: "7",
        src: javaa,
      },
      {
        title: "Data Science",
        url: "datascience",
        duration: "35hr 10min",
        projects: "7",
        src: ds,
      },
      {
        title: "MERN Stack",
        url: "mernstack",
        duration: "35hr 10min",
        projects: "7",
        src: mern,
      },
    ],
    careers: [
      {
        title: "Full-Stack Developer",
        img: tg1,
        desc: "Build and maintain web applications using the MERN stack (MongoDB, Express.js, React, Node.js).",
      },
      {
        title: "Data Scientist",
        img: tg2,
        desc: "Design, develop, and test machine learning systems and implement ML algorithms.",
      },
      {
        title: "Ethical Hacker",
        img: tg3,
        desc: "Identify and mitigate security threats by testing and securing computer systems.",
      },
      {
        title: "App Developer",
        img: tg4,
        desc: "Identify and mitigate security threats by testing and securing computer systems.",
      },
    ],
    tools: [html, css, js, mongo, express, react, node, boots, vsc],
  },
  fullstackdev: {
    pagetitle: "Full Stack Dev Pack",
    heroHead: "Full Stack Dev",
    price: 1999,
    curric: [
      {
        title: "Mern Stack",
        url: "mernstack",
        duration: "35hr 10min",
        projects: "7",
        src: mern,
      },
      {
        title: "React JS",
        url: "reactjs",
        duration: "35hr 10min",
        projects: "7",
        src: reactt,
      },
      {
        title: "DSA With Python",
        url: "dsawithpython",
        duration: "35hr 10min",
        projects: "7",
        src: dsa,
      },
      {
        title: "Devops",
        url: "devops",
        duration: "35hr 10min",
        projects: "7",
        src: devops,
      },
      {
        title: "Python",
        url: "python",
        duration: "35hr 10min",
        projects: "7",
        src: python,
      },
      {
        title: "DBMS",
        url: "dbms",
        duration: "35hr 10min",
        projects: "7",
        src: dbms,
      },
    ],
    careers: [
      {
        title: "Full-Stack Developer",
        img: fs1,
        desc: "Build and maintain complete web applications using front-end and back-end technologies.",
      },
      {
        title: "Front-End Developer",
        img: fs2,
        desc: "Develop responsive and dynamic user interfaces with React Js.",
      },
      {
        title: "Back-End Developer",
        img: fs3,
        desc: "Create and manage databases and server-side logic with Node.js and MongoDB.",
      },
      {
        title: "DevOps Engineer",
        img: fs4,
        desc: "Automate and streamline development operations to enhance deployment and integration.",
      },
    ],
    tools: [html, css, js, mongo, express, react, node, boots, vsc],
  },
  business: {
    pagetitle: "Finance Pack",
    heroHead: "Finance",
    price: 1499,
    curric: [
      {
        title: "Financial Modelling valuation",
        url: "financialmodelling",
        duration: "35hr 10min",
        projects: "7",
        src: fmv,
      },

      {
        title: "Financial Risk Management",
        url: "riskmanagement",
        duration: "35hr 10min",
        projects: "7",
        src: frm,
      },
      {
        title: "Stock Market",
        url: "fundamentalanalysis",
        duration: "35hr 10min",
        projects: "7",
        src: stm,
      },
      {
        title: "Technical Research",
        url: "technicalanalysis",
        duration: "35hr 10min",
        projects: "7",
        src: tecr,
      },
    ],
    careers: [
      {
        title: "Financial Analyst",
        img: b1,
        desc: "Analyze financial data and provide insights to guide investment decisions.",
      },
      {
        title: "Stock Market Analyst",
        img: b2,
        desc: "Evaluate stock market trends and advise on investment strategies.",
      },
      {
        title: "Risk Manager",
        img: b3,
        desc: "Identify and mitigate financial risks within an organization.",
      },
      {
        title: "Financial Consultant",
        img: b4,
        desc: "Offer expert financial planning and advice to clients.",
      },
    ],
    // tools: [dst1, dst2],
  },
  designing: {
    price: 1499,
    pagetitle: "Designers Pack",
    heroHead: "Designers",
    curric: [
      {
        title: "Adobe Illustrator",
        url: "illustrator",
        duration: "35hr 10min",
        projects: "7",
        src: aii,
      },
      {
        title: "Adobe Photoshop",
        url: "photoshop",
        duration: "35hr 10min",
        projects: "7",
        src: pss,
      },
      {
        title: "UI/UX",
        url: "uiux",
        duration: "35hr 10min",
        projects: "7",
        src: figma,
      },
      {
        title: "UX Research",
        url: "uxresearch",
        duration: "35hr 10min",
        projects: "7",
        src: uxresearch,
      },
    ],
    careers: [
      {
        title: "UI/UX Designer",
        img: c1,
        desc: "Design intuitive and engaging user interfaces for websites and applications.",
      },
      {
        title: "Graphic Designer",
        img: c2,
        desc: "Create visually appealing graphics and layouts using Photoshop and Illustrator.",
      },
      {
        title: "Web Designer",
        img: c3,
        desc: "Develop aesthetically pleasing and functional websites with a focus on user experience.",
      },
      {
        title: "Product Designer",
        img: c4,
        desc: "Lead creative projects and teams to develop innovative visual concepts.",
      },
    ],
    tools: [figma, ai, ps, miro],
  },
  appdev: {
    price: 1999,
    pagetitle: "App Development Pack",
    heroHead: "App Development",
    curric: [
      {
        title: "Java",
        url: "java",
        duration: "35hr 10min",
        projects: "7",
        src: java,
      },
      {
        title: "Flutter",
        url: "flutter",
        duration: "35hr 10min",
        projects: "7",
        src: flutter,
      },
      {
        title: "React Native",
        url: "reactnative",
        duration: "35hr 10min",
        projects: "7",
        src: react,
      },
      {
        title: "React JS",
        url: "reactjs",
        duration: "35hr 10min",
        projects: "7",
        src: react,
      },
      {
        title: "App development",
        url: "appdevelopment",
        duration: "35hr 10min",
        projects: "7",
        src: ad,
      },
    ],
    careers: [
      {
        title: "Mobile App Developer",
        img: ad1,
        desc: "Design and develop mobile applications using Flutter and React Native.",
      },
      {
        title: "Front-End Developer",
        img: ad2,
        desc: "Build and maintain user interfaces with React Js.",
      },
      {
        title: "Software Engineer",
        img: ad3,
        desc: "Create and maintain software solutions using Java.",
      },
      {
        title: "Full-Stack Developer",
        img: ad4,
        desc: "Develop end-to-end mobile applications and integrate back-end services.",
      },
    ],
    tools: [react, java, vsc, astd, ecclipse, netbeams],
  },
};

export default data;
