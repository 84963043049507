import React, { useEffect, useRef, useState } from "react";
import "./layout.css";
import "../global.css";
import Navbar from "../../Components/HomePage/Components/Navbar";
import Experiences from "../../Components/Experiences";
import Faq from "../../InternshipTrack/pages/home/Faq";
import Footer from "../../InternshipTrack/pages/home/Footer";
import Hero from "./home/Hero";
import Refund from "./home/Refund";
import Courses from "./home/Courses";
import OtherCourses from "./home/OtherCourses";
import CustomPackRefund from "./home/CustomPackRefund";
import { Helmet } from "react-helmet";
import SignUp from "../../Components/SignUp";
import Login from "../../Components/Login";
import ForgotPass from "../../Components/ForgotPass";
import FloatSelected from "../../Components/CustomPack/FloatSelected";
import VideoCarousel from "../../Components/VideoCarousel";

function Layout(props) {
  const [showLogin, setShowLogin] = useState(false);
  const [showSign, setShowSign] = useState(false);
  const customSlideToExploreRef = useRef(null);
  const [showForgot, setShowForgot] = useState(false);
  const [showFloatSelected, setShowFloatSelected] = useState(false);
  const [redirect, setRedirect] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      if (customSlideToExploreRef.current) {
        const customSlideToExploreRect =
          customSlideToExploreRef.current.getBoundingClientRect();
        const scrollPosition = window.scrollY || window.pageYOffset;
        if (scrollPosition >= customSlideToExploreRect.top) {
          setShowFloatSelected(true);
        } else {
          setShowFloatSelected(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.gtag("event", "conversion", {
      send_to: "AW-711435738/CRmfCMKls7oDENrLntMC",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Customized Pack page</title>
      </Helmet>
      {showSign && (
        <SignUp
          setShowSign={setShowSign}
          redirect={redirect}
          setShowLogin={setShowLogin}
        />
      )}
      {showLogin && (
        <Login
          setShowLogin={setShowLogin}
          redirect={redirect}
          setShowSign={setShowSign}
          setShowForgot={setShowForgot}
        />
      )}
      {showForgot && <ForgotPass setShowForgot={setShowForgot} />}
      <div className="ctm-pack-layout">
        <Navbar setShowLogin={setShowLogin} setShowSign={setShowSign} />
        <Hero setShowLogin={setShowSign} setRedirect={setRedirect} />
        <Refund />
        <div ref={customSlideToExploreRef}>
          <CustomPackRefund />
        </div>
        <Courses />
        <OtherCourses custom={true} />
        <VideoCarousel />
        <Experiences />
        <Faq />
        <Footer />
        {showFloatSelected && (
          <FloatSelected setShowLogin={setShowSign} setRedirect={setRedirect} />
        )}
      </div>
    </>
  );
}

export default Layout;
