import React, { useEffect, useRef, useState } from "react";
import "./layout.css";
import Navbar from "../../Components/HomePage/Components/Navbar";
import Experiences from "../../Components/Experiences";
import Faq from "../../InternshipTrack/pages/home/Faq";
import Footer from "../../InternshipTrack/pages/home/Footer";

import Hero from "./home/Hero";
import Refund from "./home/Refund";
import Courses from "./home/Courses";
import OtherCourses from "../../Components/OtherCourses";
// import OtherCourses from "../../CustomPack/pages/home/OtherCourses";
import "../global.css";
import ForgotPass from "../../Components/ForgotPass";
import Login from "../../Components/Login";
import SignUp from "../../Components/SignUp";
import { Helmet } from "react-helmet";
import FloatSelectedUpgrade from "../../Components/Upgrade/FloatSelectedUpgrade";
import VideoCarousel from "../../Components/VideoCarousel";
function Layout(props) {
  const [showLogin, setShowLogin] = useState(false);
  const [showSign, setShowSign] = useState(false);
  const customSlideToExploreRef = useRef(null);
  const [redirect, setRedirect] = useState(null);
  const [showForgot, setShowForgot] = useState(false);
  const [showFloatSelected, setShowFloatSelected] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    const handleScroll = () => {
      if (customSlideToExploreRef.current) {
        const customSlideToExploreRect =
          customSlideToExploreRef.current.getBoundingClientRect();
        const scrollPosition = window.scrollY || window.pageYOffset;
        if (scrollPosition >= customSlideToExploreRect.top) {
          setShowFloatSelected(true);
        } else {
          setShowFloatSelected(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="upg-pag-layout">
        <Helmet>
          <title>Upgrade page</title>
        </Helmet>
        {showSign && (
          <SignUp
            setShowSign={setShowSign}
            redirect={redirect}
            setShowLogin={setShowLogin}
          />
        )}
        {showLogin && (
          <Login
            setShowLogin={setShowLogin}
            redirect={redirect}
            setShowSign={setShowSign}
            setShowForgot={setShowForgot}
          />
        )}
        {showForgot && <ForgotPass setShowForgot={setShowForgot} />}
        <Navbar setShowLogin={setShowLogin} setShowSign={setShowSign} />
        <Hero setRedirect={setRedirect} setShowLogin={setShowSign} />

        <div ref={customSlideToExploreRef}>
          <Refund />
        </div>
        <Courses />
        {/* <OtherCourses /> */}
        <OtherCourses
          hide={1}
          upg={true}
          setRedirect={setRedirect}
          setShowLogin={setShowSign}
        />
        <VideoCarousel />
        <Experiences />
        <Faq />
        <Footer />
        {showFloatSelected && (
          <FloatSelectedUpgrade
            setRedirect={setRedirect}
            setShowLogin={setShowSign}
          />
        )}
      </div>
    </>
  );
}

export default Layout;
