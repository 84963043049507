import React from "react";
import "./Styles/Certificate.css";
import certificate from "../Assests/certificate.png";
const Certificate = ({ name }) => {
  return (
    <div className="cert-cont" id="certificate">
      <div className="headings-col">{`Certify your Learning`}</div>
      {/* <div className='headings-col'>{`Certify your ${name} Mastery`}</div> */}
      <div className="cert-text">{`Complete the course with assignments, and request your course completion certificate `}</div>
      <div className="cert-img">
        <img src={certificate} alt="certificate" />
      </div>
    </div>
  );
};

export default Certificate;
