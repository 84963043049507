import React, { useEffect, useState } from "react";
import "../CustomPack/styles/FloatSelected.css";

import useCart from "../../contexts/Cart";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import courses from "../../contents/Courses.json";
const AllFloatSelected = ({ setShowLogin, setRedirect }) => {
  const { setSelectedCourse, setSelectedCategory } = useCart();

  const [name, setName] = useState(Cookies.get("user_name"));
  useEffect(() => {
    setName(Cookies.get("user_name"));
  }, []);
  const navigate = useNavigate();

  return (
    <div className="float-s-cont">
      <div className="float-s-inner-cont">
        <div className="flc-course-cont">
          26
          <span>Selected Courses</span>
        </div>
        <div className="flc-price-cont">
          ₹ 4499 <span>Total Price</span>
        </div>

        <div
          className="flc-cta pointer"
          onClick={() => {
            setSelectedCategory("allAccess");
            if (!name) {
              setSelectedCourse((prev) => ({
                ...prev,
                allAccess: [courses],
              }));
              setRedirect("/packpayment");
              setShowLogin(true);
            } else {
              setSelectedCourse((prev) => ({
                ...prev,
                allAccess: [courses],
              }));

              navigate(`/packpayment`);
            }
          }}
        >
          Proceed
        </div>
      </div>
    </div>
  );
};

export default AllFloatSelected;
